import { forwardRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

import styled from 'styled-components';

import Section from '../../../components/Section';
import PaymentMethod from './PaymentMethod';

const Price = forwardRef(({ quote }, ref) => {
  const [paymentProvider, setPaymentProvider] = useState('trustly');
  const history = useHistory();

  const scaleAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 200,
    },
  });

  const { number } = useSpring({
    from: { number: 0 },
    number: quote.premium.monthly,
  });

  const handlePaymentProvoderChagne = event =>
    setPaymentProvider(event.target.value);

  const handlePurchaseClick = () =>
    history.push(`/pay/${paymentProvider}/${quote.id}`);

  return (
    <PriceSection ref={ref} style={scaleAnimation}>
      <header>
        <h3>
          Ditt pris för
          <br />{' '}
          {quote.answers.product === 'hajpe-rf-motor'
            ? quote.carMake
            : quote.answers.product === 'hajpe-rf-allrisk'
            ? `${quote.firstName} ${quote.lastName}`
            : quote.answers.address}
        </h3>
      </header>
      <Main>
        <MonthlyPrice>
          <animated.span>
            {number.to(n =>
              n.toLocaleString('sv-SE', {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })
            )}
          </animated.span>{' '}
          <span>kr/månad*</span>
        </MonthlyPrice>
        <PaymentMethod
          paymentProvider='trustly'
          selectedPaymentProvider={paymentProvider}
          onChange={handlePaymentProvoderChagne}
        />
        <PaymentMethod
          paymentProvider='nets'
          selectedPaymentProvider={paymentProvider}
          onChange={handlePaymentProvoderChagne}
        />
        <ButtonWrapper>
          <Button size='medium' theme='green' onClick={handlePurchaseClick}>
            Teckna försäkring
          </Button>
        </ButtonWrapper>
      </Main>
      <Footer>
        <p>
          * Den premie som visas bygger på ditt pris för ett års försäkring och
          delas sedan på 12 månader. Din faktiska månadspremie beräknas på exakt
          antal dagar i månaden.
        </p>
      </Footer>
    </PriceSection>
  );
});

export default Price;

const PriceSection = styled(animated(Section))`
  color: #fff;
  background: rgb(23, 161, 126);
  background: linear-gradient(
    133deg,
    rgba(0, 133, 74, 0.5) 0%,
    rgba(0, 133, 74, 0.75) 40%,
    rgba(0, 133, 74, 1) 100%
  );
  transform-origin: top;

  h3 {
    color: #fff;
  }

  main {
  }
`;

const MonthlyPrice = styled.span`
  font-size: 3rem;
  font-weight: 500;

  span {
    &:last-child {
      font-size: 1.5rem;
    }
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Footer = styled.footer`
  border-top: 0.0625rem solid #fff;
  padding-top: 0.5rem;
  p {
    font-size: 0.8rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Button = styled.button`
  border-radius: 0.25rem;
  line-height: 1;
  background-color: #00854a;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 1.2rem 0;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(30, 56, 174, 0.3) 0 1px 3px 1px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
