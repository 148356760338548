import { useRef } from 'react';
import styled from 'styled-components';

const RadioButton = ({ name, value, text, onChange, checked, info }) => {
  const inputRef = useRef(null);
  // const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   if (inputRef.current) {
  //     setIsChecked(inputRef.current.checked);
  //   }
  // }, [inputRef.current?.checked]);

  // useEffect(() => {
  //   if (inputRef.current) {
  //     console.log('REF är satt!!!');
  //   }
  // }, []);

  return (
    <RadioButtonLabel isChecked={checked} hasInfo={info ? true : false}>
      <InputWrapper>
        <Input
          ref={inputRef}
          name={name}
          type='radio'
          value={value}
          onChange={onChange}
          checked={checked}
        />
        {text
          ? text
          : value === 'true'
          ? 'Ja'
          : value === 'false'
          ? 'Nej'
          : Number.isNaN(Number(value))
          ? value
          : Number(value).toLocaleString('sv-SE')}
      </InputWrapper>
      {info}
    </RadioButtonLabel>
  );
};

export default RadioButton;

const Input = styled.input`
  height: 1.25rem;
  width: 1.25rem;
  padding: 0;
  appearance: none;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 0.1875rem solid #fff;
  box-shadow: 0 0 0 0.0625rem #dedede;
  cursor: pointer;

  &:checked {
    background-color: #00854a;
    box-shadow: 0 0 0 0.0625rem #00854a;

    &:hover {
      background-color: #00854a;
      box-shadow: 0 0 0 0.0625rem #00854a;
    }
  }

  &:hover {
    border: 0.1875rem solid #fff;
    box-shadow: 0 0 0 0.0625rem #dedede;
  }
`;

const RadioButtonLabel = styled.label`
  border: 0.125rem solid
    ${({ isChecked }) => (isChecked ? '#00854a' : 'transparent')};

  border-radius: ${({ hasInfo }) => (hasInfo ? '0.5rem' : '2.2rem')};
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${({ isChecked }) =>
    isChecked ? '#edfcf9' : 'transparent'};
  font-size: 1rem;
  align-items: center;
  align-self: flex-start;
  min-width: 33%;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  align-self: flex-start;
`;
