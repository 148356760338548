import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { createQuoteService } from '@eir/services';
import { formatDate } from '@eir/utils';

import Header from '../../components/Header';
import SalesFormAllrisk from './components/SalesFormAllrisk';
import SalesFormMotor from './components/SalesFormMotor';
import Documents from './components/Documents';
import Price from './components/Price';
import useForm from '../../hooks/useForm';
import NoQuote from './components/NoQuote';
import SalesFormContents from './components/SalesFormContents';
import SalesFormHouseowner from './components/SalesFormHouseowner';

const OnlineSalesView = () => {
  const { product } = useParams();

  const productTranslations = {
    allrisk: 'hajpe-rf-allrisk',
    hem: 'hajpe-rf-contents',
    villahem: 'hajpe-rf-houseowner',
    motor: 'hajpe-rf-motor',
  };

  const buttonTexts = {
    default: 'Beräkna ditt pris',
    calculated: 'Ditt pris är beräknat',
    calculating: 'Vi beräknar ditt pris',
    calculateNew: 'Beräkna nytt pris',
  };

  const [quoteResponse, setQuoteResponse] = useState(null);
  const [hasQuoteError, setHasQuoteError] = useState(false);
  const [isCalculatingPrice, setIsCalculatingPrice] = useState(false);
  const [buttonText, setButtonText] = useState(buttonTexts.default);
  const [outbuildings, setOutbuildings] = useState([]);

  const priceRef = useRef(null);
  const errorRef = useRef(null);

  const header = {
    allrisk: 'Teckna allrisk',
    hem: 'Teckna hemförsäkring',
    villahem: 'Teckna Villahemförsäkring',
    motor: 'Teckna bilförsäkring',
  };

  const initialValues = {
    allrisk: {
      coverLevel: 'Allrisk-Hem',
      email: '',
      paymentMethod: 'Monthly',
      product: 'hajpe-rf-allrisk',
      productVersion: 'latest',
      socialSecurity: '',
      startDate: new Date(),
      reference: 'osa',
      telephoneNumber: '',
    },
    hem: {
      area: '70',
      coverLevel: 'Base',
      email: '',
      excess: '1500',
      optCondoAddOn: 'true',
      optExtra: 'true',
      optTravel: 'true',
      paymentMethod: 'Monthly',
      product: 'hajpe-rf-contents',
      productVersion: 'latest',
      reference: 'osa',
      securitySolution: 'Nej',
      startDate: new Date(),
      rentedOut: 'Nej',
      residents: '1',
      sumInsured: '300000',
      telephoneNumber: '',
    },
    villahem: {
      area: '70',
      basement: 'Nej',
      bathrooms: '2',
      biarea: '0',
      buildingAge: '23',
      buildingYear: '2000',
      coverLevel: 'VillaHem',
      email: '',
      excess: '1500',
      optExtendedContents: 'true',
      optExtendedVilla: 'true',
      optTravel: 'true',
      outbuilding1Area: '0',
      outbuilding1Heat: 'Nej',
      outbuilding2Area: '0',
      outbuilding2Heat: 'Nej',
      outbuilding3Area: '0',
      outbuilding3Heat: 'Nej',
      outbuilding4Area: '0',
      outbuilding4Heat: 'Nej',
      outbuilding5Area: '0',
      outbuilding5Heat: 'Nej',
      outbuildings: '0',
      owner: 'Ja',
      ownershipYears: '10',
      paymentMethod: 'Monthly',
      product: 'hajpe-rf-houseowner',
      productVersion: 'latest',
      reference: 'osa',
      securitySolution: 'Nej',
      startDate: new Date(),
      rentedOut: 'Nej',
      residents: '1',
      sumInsured: '300000',
      telephoneNumber: '',
      waterborneHeat: 'Nej',
      yearBought: '2010',
    },
    motor: {
      coverLevel: 'Halv',
      dwelling: 'Villa',
      email: '',
      excessLevel: '4500',
      socialSecurity: '',
      product: 'hajpe-rf-motor',
      productVersion: 'latest',
      reference: 'osa',
      registrationNumber: '',
      startDate: new Date(),
      parking: 'Egen tomt',
      paymentMethod: 'Monthly',
      telephoneNumber: '',
      mileage: '0-1000',
      youngDriver: 'Nej',
      optAccident: 'true',
      optAnimalCollision: 'true',
      optAssistance: 'true',
      optComprehensive: 'true',
      optDeductible: 'true',
      optHiredCar: 'true',
      claimFreeYears: 'Missing',
    },
  };

  const validations = {
    allrisk: {
      email: {
        required: {
          value: true,
          message: 'Fyll i din e-postadress',
        },
        pattern: {
          value: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
          message: 'Ange en giltig e-postadress',
        },
      },
      telephoneNumber: {
        required: {
          value: true,
          message: 'Fyll i ditt telefonnummer',
        },
        pattern: {
          value: '(07)[0-9]{8}$',
          message: 'Ange telefonnummer i formatet 07nnnnnnnn',
        },
      },
      socialSecurity: {
        required: {
          value: true,
          message: 'Fyll i ditt personnummer',
        },
        pattern: {
          value: '[0-9]{12}$',
          message: 'Fyll i ditt personnummer i formatet ÅÅÅÅMMDDnnnn',
        },
      },
    },
    hem: {
      email: {
        required: {
          value: true,
          message: 'Fyll i din e-postadress',
        },
        pattern: {
          value: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
          message: 'Ange en giltig e-postadress',
        },
      },
      telephoneNumber: {
        required: {
          value: true,
          message: 'Fyll i ditt telefonnummer',
        },
        pattern: {
          value: '(07)[0-9]{8}$',
          message: 'Ange telefonnummer i formatet 07nnnnnnnn',
        },
      },
      socialSecurity: {
        required: {
          value: true,
          message: 'Fyll i ditt personnummer',
        },
        pattern: {
          value: '[0-9]{12}$',
          message: 'Fyll i ditt personnummer i formatet ÅÅÅÅMMDDnnnn',
        },
      },
    },
    villahem: {
      email: {
        required: {
          value: true,
          message: 'Fyll i din e-postadress',
        },
        pattern: {
          value: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
          message: 'Ange en giltig e-postadress',
        },
      },
      telephoneNumber: {
        required: {
          value: true,
          message: 'Fyll i ditt telefonnummer',
        },
        pattern: {
          value: '(07)[0-9]{8}$',
          message: 'Ange telefonnummer i formatet 07nnnnnnnn',
        },
      },
      socialSecurity: {
        required: {
          value: true,
          message: 'Fyll i ditt personnummer',
        },
        pattern: {
          value: '[0-9]{12}$',
          message: 'Fyll i ditt personnummer i formatet ÅÅÅÅMMDDnnnn',
        },
      },
    },
    motor: {
      email: {
        required: {
          value: true,
          message: 'Fyll i din e-postadress',
        },
        pattern: {
          value: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
          message: 'Ange en giltig e-postadress',
        },
      },
      telephoneNumber: {
        required: {
          value: true,
          message: 'Fyll i ditt telefonnummer',
        },
        pattern: {
          value: '(07)[0-9]{8}$',
          message: 'Ange telefonnummer i formatet 07nnnnnnnn',
        },
      },
      registrationNumber: {
        required: {
          value: true,
          message: 'Fyll i bilens registreringsnummer',
        },
      },
      socialSecurity: {
        required: {
          value: true,
          message: 'Fyll i ditt personnummer',
        },
        custom: {
          isValid: value => value.replace('-', '').length === 10,
          message: 'Fyll i ditt personnummer',
        },
      },
      youngDriver: {
        required: {
          value: true,
          message: 'Ange om det finns förare under 25 år',
        },
      },
    },
  };

  const addOutbuilding = () =>
    setOutbuildings(state => [...state, { area: '30', heat: 'Nej' }]);

  const changeOutbuilding = (area, heat, index) => {
    setOutbuildings(
      outbuildings.map((outbuilding, i) =>
        i !== index
          ? outbuilding
          : {
              area,
              heat,
            }
      )
    );
  };

  const removeOutbulding = index => {
    setOutbuildings(outbuildings.filter((_, i) => i !== index));
  };

  const { handleSubmit, handleChange, data, setData, errors } = useForm({
    initialValues: initialValues[product],
    validations: validations[product],
    onSubmit: e => {
      setIsCalculatingPrice(true);
      setButtonText(buttonTexts.calculating);

      const outbuildingsForRequest = outbuildings.reduce((a, b, index) => {
        return {
          ...a,
          [`outbuilding${index + 1}Area`]: b.area,
          [`outbuilding${index + 1}Heat`]: b.heat,
        };
      }, {});

      const body = { ...data, ...outbuildingsForRequest };

      if (outbuildings?.length > 0) {
        body.outbuildings = outbuildings.length.toString();
      }

      if (data.yearBought) {
        body.ownershipYears = (
          new Date().getFullYear() - data.yearBought
        ).toString();
      }

      if (data.product === 'hajpe-rf-motor') {
        // They belong together in T&C
        body.optAnimalCollision = body.optDeductible;
      }

      body.startDate = formatDate(body.startDate);
      body.socialSecurity = body.socialSecurity.replace('-', '');

      const createQuote = async () => {
        try {
          setQuoteResponse(await createQuoteService('Kraken', body));
          setHasQuoteError(false);
          setButtonText(buttonTexts.calculated);

          setTimeout(() => {
            setButtonText(buttonTexts.calculateNew);
          }, 1500);

          if (priceRef.current) {
            priceRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        } catch (error) {
          setHasQuoteError(true);
          setButtonText(buttonTexts.default);

          if (errorRef.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        } finally {
          setIsCalculatingPrice(false);
        }
      };

      createQuote();
    },
  });

  useEffect(() => {
    if (data.product === 'hajpe-rf-houseowner') {
      setData(state => ({
        ...state,
        optExtendedContents: data.coverLevel === 'VillaHem' ? 'true' : 'false',
      }));
    }
  }, [data.coverLevel, data.product]);

  return (
    <PageWrapper>
      <Header>
        <H1>{header[product]}</H1>
      </Header>
      <ContentWrapper>
        <main>
          {product === 'allrisk' && (
            <SalesFormAllrisk
              data={data}
              errors={errors}
              onChange={handleChange}
              onSubmit={handleSubmit}
              buttonText={buttonText}
              isCalculatingPrice={isCalculatingPrice}
            />
          )}
          {product === 'hem' && (
            <SalesFormContents
              data={data}
              errors={errors}
              onChange={handleChange}
              onSubmit={handleSubmit}
              buttonText={buttonText}
              isCalculatingPrice={isCalculatingPrice}
            />
          )}
          {product === 'motor' && (
            <SalesFormMotor
              data={data}
              errors={errors}
              onChange={handleChange}
              onSubmit={handleSubmit}
              buttonText={buttonText}
              isCalculatingPrice={isCalculatingPrice}
            />
          )}
          {product === 'villahem' && (
            <SalesFormHouseowner
              data={data}
              errors={errors}
              onChange={handleChange}
              onSubmit={handleSubmit}
              buttonText={buttonText}
              isCalculatingPrice={isCalculatingPrice}
              outbuildings={outbuildings}
              onAddOutbuilding={addOutbuilding}
              onChangeOutbuilding={changeOutbuilding}
              onRemoveOutbuilding={removeOutbulding}
            />
          )}
        </main>
        <Aside>
          {hasQuoteError ? (
            <NoQuote ref={errorRef} />
          ) : (
            quoteResponse && <Price ref={priceRef} quote={quoteResponse} />
          )}
          <Documents productName={productTranslations[product]} />
        </Aside>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default OnlineSalesView;

const PageWrapper = styled.div`
  background-color: rgb(237, 246, 242);
  margin: auto;
  padding-bottom: 2rem;

  /* @media (min-width: 54rem) {
    padding: 0;
  } */
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(100%, 37.5rem);
  gap: 1rem;
  justify-content: center;

  @media (min-width: 54rem) {
    grid-template-columns: minmax(auto, 42rem) 18.25rem;
    padding: 0 2rem;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 54rem) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;

const H1 = styled.h1`
  text-align: center;

  span {
    white-space: nowrap;
  }
`;
