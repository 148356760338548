import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/datepicker-fix.css';

import Section from '../../../components/Section';
import RadioButton from './RadioButton';
import RangeSlider from './RangeSlider';

const SalesFormContents = ({
  data,
  errors,
  onChange,
  onSubmit,
  buttonText,
  isCalculatingPrice,
}) => {
  const handleDateChange = date => {
    return date;
  };

  const handleDateChangeRaw = e => e.preventDefault();

  return (
    <Section>
      <InputContainer>
        <label>Personnummer</label>
        <input
          name='socialSecurity'
          value={data.socialSecurity}
          onChange={onChange('socialSecurity')}
          autofocus
          placeholder='ÅÅÅÅMMDDnnnn'
        />
        <ValidationErrorText>{errors?.socialSecurity}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>Boyta</label>
        <RangeSlider
          name='area'
          value={data.area}
          minValue={15}
          maxValue={200}
          onChange={onChange('area')}
        />
      </InputContainer>
      <InputContainer>
        <label>Antal personer i hushållet</label>
        <RangeSlider
          name='residents'
          value={data.residents}
          minValue={1}
          maxValue={8}
          onChange={onChange('residents')}
        />
        <ValidationErrorText>{errors?.residents}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>Har du säkerhetsdörr</label>
        <RadioButtonsContainer>
          {['Ja', 'Nej'].map(item => (
            <RadioButton
              key={item}
              name='securitySolution'
              value={item}
              onChange={onChange('securitySolution')}
              checked={data.securitySolution === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Självrisk</label>
        <RadioButtonsContainer>
          {['1500', '1800', '2000', '3000', '6000'].map(item => (
            <RadioButton
              key={item}
              name='excess'
              value={item}
              text={`${Number(item).toLocaleString('sv-SE')} kr`}
              onChange={onChange('excess')}
              checked={data.excess === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Försäkringsbelopp för egendom</label>
        <RadioButtonsContainer>
          {['300000', '1000000', '1500000', '2000000'].map(item => (
            <RadioButton
              key={item}
              name='sumInsured'
              value={item}
              text={`${Number(item).toLocaleString('sv-SE')} kr`}
              onChange={onChange('sumInsured')}
              checked={data.sumInsured === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Bostadsrättstillägg</label>
        <RadioButtonsContainer>
          {['true', 'false'].map(item => (
            <RadioButton
              key={item}
              name='optCondoAddOn'
              value={item}
              text={item === 'true' ? 'Ja' : 'Nej'}
              onChange={onChange('optCondoAddOn')}
              checked={data.optCondoAddOn === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Extraskydd hem</label>
        <RadioButtonsContainer>
          {['true', 'false'].map(item => (
            <RadioButton
              key={item}
              name='optExtra'
              value={item}
              text={item === 'true' ? 'Ja' : 'Nej'}
              onChange={onChange('optExtra')}
              checked={data.optExtra === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Extraskydd resa</label>
        <RadioButtonsContainer>
          {['true', 'false'].map(item => (
            <RadioButton
              key={item}
              name='optTravel'
              value={item}
              text={item === 'true' ? 'Ja' : 'Nej'}
              onChange={onChange('optTravel')}
              checked={data.optTravel === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Är bostaden uthyrd?</label>
        <RadioButtonsContainer>
          {['Ja', 'Nej'].map(item => (
            <RadioButton
              key={item}
              name='rentedOut'
              value={item}
              onChange={onChange('rentedOut')}
              checked={data.rentedOut === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Startdatum</label>
        <DatePicker
          locale='sv'
          dateFormat='yyyy-MM-dd'
          minDate={new Date()}
          selected={data.startDate || null}
          onChange={onChange('startDate', handleDateChange)}
          onChangeRaw={handleDateChangeRaw}
        />
      </InputContainer>
      <InputContainer>
        <label>Mobilnummer</label>
        <input
          type='text'
          value={data.telephoneNumber}
          onChange={onChange('telephoneNumber')}
        />
        <ValidationErrorText>{errors?.telephoneNumber}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>E-post</label>
        <input type='text' value={data.email} onChange={onChange('email')} />
        <ValidationErrorText>{errors?.email}</ValidationErrorText>
      </InputContainer>
      <button type='button' disabled={isCalculatingPrice} onClick={onSubmit}>
        {buttonText}
      </button>
    </Section>
  );
};

export default SalesFormContents;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  label {
  }
`;

const ValidationErrorText = styled.span`
  font-size: 0.8125rem;
  color: #f2545b;
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.25rem;
  flex-wrap: wrap;
`;
