import styled from 'styled-components';
import Header from '../../components/Header';

const PurchaseSuccessView = () => {
  return (
    <>
      <Header />
      <ContentContainer>
        <h1>Grattis till din försäkring!</h1>
        <h2>Nu är allt klart!</h2>
        <p>
          Inom 1 dag kommer ditt försäkringsbrev och betalplan skickas som ett
          SMS till det mobilnummer du angivit. Om du har några frågor är du
          välkommen att kontakta Eir Försäkring.
        </p>
        <h3>Får du inget SMS?</h3>
        <p>
          Den vanligaste orsaken till att du inte mottagit en bekräftelse via
          SMS är att ditt mobilnummer inte angivits eller blivit felaktigt
          angivet i beställningen.
        </p>
        <p>
          Vänligen kontakta oss så hjälper vi dig. Du når oss enklast på{' '}
          <a href='mailto:info@eirforsakring.se'>info@eirforsakring.se</a>.
        </p>
        <h2>Du kan känna dig trygg!</h2>
        <p>
          Eir Försäkring är försäkringsgivare åt Reumatikerförbundet och har i
          uppdrag att ta sköta försäkringsadministrationen åt
          Reumatikerförbundet försäkringskunder. Eir Försäkring har tillstånd av
          Finansinspektionen att bedriva försäkringsrörelse och är således under
          tillsyn. Bakom oss har vi ett par av världens största försäkringsbolag
          som återförsäkrar oss, vilket garanterar dina utbetalningar som kund.
        </p>
      </ContentContainer>
    </>
  );
};

export default PurchaseSuccessView;

const ContentContainer = styled.div`
  padding: 0;
  max-width: 40rem;
  margin: auto;

  @media (min-width: 32rem) {
    grid-template-columns: 1fr 1fr;
  }

  h1 {
    font-size: 1.6rem;
    line-height: 1.3;
    font-weight: 700;
    text-align: center;

    @media (min-width: 35rem) {
      font-size: 3.125rem;
    }
  }

  h2 {
    line-height: 1.3;
  }

  h3 {
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 0.5rem;
  }
`;
