import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import netsLogo from '../../../images/nets.svg';
import trustlyLogo from '../../../images/trustly.png';

const PaymentMethod = ({
  paymentProvider,
  selectedPaymentProvider,
  onChange,
}) => {
  const inputRef = useRef(null);
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (inputRef.current) {
      setIsChecked(inputRef.current.checked);
    }
  }, [inputRef.current?.checked]);

  const paymentProviderSettings = (() => {
    switch (paymentProvider) {
      case 'nets':
        return {
          typeOfPayment: 'Kort',
          logo: <img src={netsLogo} alt='Nets' />,
        };
      case 'trustly':
        return {
          typeOfPayment: 'Autogiro',
          logo: <img src={trustlyLogo} alt='Trustly' />,
        };
      default:
        return null;
    }
  })();

  return (
    <PaymentMethodLabel isChecked={isChecked}>
      <div>
        <Input
          ref={inputRef}
          type='radio'
          name='paymentMethod'
          value={paymentProvider}
          onChange={onChange}
          checked={paymentProvider === selectedPaymentProvider}
        />
      </div>
      <TextWrapper>
        <span>Betala med</span>
        <span>{paymentProviderSettings?.typeOfPayment}</span>
      </TextWrapper>
      <div>{paymentProviderSettings?.logo}</div>
    </PaymentMethodLabel>
  );
};

export default PaymentMethod;

const PaymentMethodLabel = styled.label`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 0.125rem solid
    ${({ isChecked }) => (isChecked ? '#00854a' : '#dedede')};
  background-color: ${({ isChecked }) =>
    isChecked ? 'rgba(237, 252, 249, 0.8)' : 'rgba(245, 245, 245, 0.4)'};
  border-radius: 0.5rem;
  align-items: center;
  color: #444;
  cursor: pointer;

  div {
    line-height: 1;
  }

  img {
    width: auto;
    height: 1.2rem;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;

  span {
    &:first-child {
      font-size: 0.8rem;
    }

    &:last-child {
      font-size: 1.2rem;
    }
  }
`;

const Input = styled.input`
  height: 1.25rem;
  width: 1.25rem;
  padding: 0;
  appearance: none;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 0.1875rem solid #fff;
  box-shadow: 0 0 0 0.0625rem #dedede;

  &:checked {
    background-color: #00854a;
    box-shadow: 0 0 0 0.0625rem #00854a;

    &:hover {
      background-color: #00854a;
      box-shadow: 0 0 0 0.0625rem #00854a;
    }
  }

  &:hover {
    border: 0.1875rem solid #fff;
    box-shadow: 0 0 0 0.0625rem #dedede;
  }
`;
