import { forwardRef } from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

import Section from '../../../components/Section';

const NoQuote = forwardRef((props, ref) => {
  return (
    <PriceSection ref={ref}>
      <header>
        <h3>Ajdå!</h3>
      </header>
      <main>
        <p>
          Tyvärr kan vi inte ge dig ett konkurrenskraftigt pris för den här
          försäkringen. Vårt råd är därför att du kontaktar andra
          försäkringsbolag.
        </p>
        <p>
          Vid frågor vänligen kontakta oss på{' '}
          <a href='mailto:info.rf@eirforsakring.se'>info.rf@eirforsakring.se</a>
          .
        </p>
      </main>
    </PriceSection>
  );
});

export default NoQuote;

const PriceSection = styled(animated(Section))`
  background: rgb(23, 161, 126);
  background: linear-gradient(
    133deg,
    rgba(255, 112, 0, 0.5) 0%,
    rgba(255, 112, 0, 0.75) 40%,
    rgba(255, 112, 0, 1) 100%
  );
  transform-origin: top;

  p {
    margin-bottom: 0.5rem;
  }

  a {
    color: #000;
  }
`;
