import { useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';

import Header from '../../components/Header';
import { purchaseService } from '@eir/services';

const PaymentView = () => {
  const match = useRouteMatch(`/pay/:paymentProvider/:quoteId`);
  const [error, setError] = useState(false);

  const redirectToPaymentProvider = async () => {
    try {
      const data = await purchaseService(
        'Kraken',
        match.params.paymentProvider,
        match.params.quoteId
      );
      window.location.replace(data.redirectUrl);
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  redirectToPaymentProvider();

  return (
    <PaymentContainer>
      <Header>
        <h1>{error ? 'Något gick fel' : 'Vänligen vänta...'}</h1>
      </Header>
      {error && (
        <main>
          Det gick inte att genomföra betalningen. Vänligen kontakta oss så
          hjälper vi dig. Du når oss enklast på{' '}
          <A href='mailto:info.rf@eirforsakring.se'>info.rf@eirforsakring.se</A>
          .
          {/* Du hittar
          även information på vår hemsida{' '}
          <A href='https://www.linforsakring.se/bilforsakring'>
            https://www.linforsakring.se/bilforsakring
          </A>
          . */}
        </main>
      )}
    </PaymentContainer>
  );
};

export default PaymentView;

const PaymentContainer = styled.div`
  width: 100%;
  max-width: 56.25rem;
  margin: 1.875rem auto;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  main {
    padding: 0.625rem;

    @media (min-width: 48rem) {
      padding: 1.875rem;
    }
  }
`;

const A = styled.a`
  text-decoration: underline;
  color: #000;

  &:hover {
    color: #5d872e;
  }
`;
