import styled from 'styled-components';

import { Icon } from '@eir/ui';
import RangeSlider from './RangeSlider';
import RadioButton from './RadioButton';

const Outbuilding = ({ outbuilding, index, onChange, onRemove }) => {
  const handleRemoveClick = () => onRemove(index);
  const handleAreaChange = e =>
    onChange(e.target.value, outbuilding.heat, index);
  const handleHeatChange = e =>
    onChange(outbuilding.area, e.target.value, index);

  return (
    <Section>
      <h3>Komplementsbyggnad {index + 1}</h3>
      <InputContainer>
        <label>Yta</label>
        <RangeSlider
          name='area'
          value={outbuilding.area}
          minValue={0}
          maxValue={90}
          onChange={handleAreaChange}
          unit='kvadratmeter'
        />
      </InputContainer>
      <InputContainer>
        <label>Har byggnaden indraget vatten?</label>
        <RadioButtonsContainer>
          {['Ja', 'Nej'].map(item => (
            <RadioButton
              key={item}
              name={`outbuilding-${index}`}
              value={item}
              onChange={handleHeatChange}
              checked={outbuilding.heat === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <Button onClick={handleRemoveClick}>
        <Icon iconName='faXmark' />
        Ta bort Fristående byggnad {index + 1}
      </Button>
    </Section>
  );
};

export default Outbuilding;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid #00854a;
  border-radius: 0.25rem;
  padding: 0.75rem;
  gap: 0.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  label {
  }
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  color: #e17000;
  background-color: transparent;
  border: 0.0625rem solid #e17000;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  align-self: flex-end;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;
