import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { getOsaPolicyService, useApiRequest } from '@eir/services';

import Header from '../../components/Header';
import PolicyOverviewMotor from './components/PolicyOverviewMotor';
import PolicyCoverageMotor from './components/PolicyCoverageMotor';
import PaymentSchedule from './components/PaymentSchedule';
import PolicyOverviewContents from './components/PolicyOverviewContents';
import PolicyCoverageContents from './components/PolicyCoverageContents';
import PolicyOverviewHouseowner from './components/PolicyOverviewHouseowner';
import PolicyCoverageHouseowner from './components/PolicyCoverageHouseowner';
import PolicyOverviewAllrisk from './components/PolicyOverviewAllrisk';

const PolicyLetterView = () => {
  const match = useRouteMatch('/p/:policyId');

  const { data } = useApiRequest(
    getOsaPolicyService('Kraken', match.params.policyId)
  );

  const getHeader = () => {
    switch (data.answers.product) {
      case 'hajpe-rf-allrisk':
        return 'Försäkringsbrev Allrisk';
      case 'hajpe-rf-contents':
        return 'Försäkringsbrev Hem';
      case 'hajpe-rf-houseowner':
        return 'Försäkringsbrev Villahem';
      case 'hajpe-rf-motor':
        return 'Försäkringsbrev bil';
      default:
        return '';
    }
  };

  return (
    data && (
      <PolicyLetterContainer>
        <Header>
          <h1>{getHeader()}</h1>
        </Header>
        <main>
          {data.answers.product === 'hajpe-rf-allrisk' && (
            <PolicyOverviewAllrisk
              answers={data.answers}
              start={data.start}
              end={data.end}
              name={data.name}
              policyNumber={data.policyNumber}
              policyPremium={data.policyPremium}
            />
          )}
          {data.answers.product === 'hajpe-rf-contents' && (
            <>
              <PolicyOverviewContents
                answers={data.answers}
                start={data.start}
                end={data.end}
                name={data.name}
                policyNumber={data.policyNumber}
                policyPremium={data.policyPremium}
              />
              <PolicyCoverageContents answers={data.answers} />
            </>
          )}
          {data.answers.product === 'hajpe-rf-houseowner' && (
            <>
              <PolicyOverviewHouseowner
                answers={data.answers}
                start={data.start}
                end={data.end}
                name={data.name}
                policyNumber={data.policyNumber}
                coverLevel={data.coverLevel}
                policyPremium={data.policyPremium}
              />
              <PolicyCoverageHouseowner
                coverLevel={data.coverLevel}
                answers={data.answers}
              />
            </>
          )}
          {data.answers.product === 'hajpe-rf-motor' && (
            <>
              <PolicyOverviewMotor
                answers={data.answers}
                start={data.start}
                end={data.end}
                name={data.name}
                policyNumber={data.policyNumber}
                coverLevel={data.coverLevel}
                policyPremium={data.policyPremium}
              />
              <PolicyCoverageMotor
                coverLevel={data.coverLevel}
                excessLevel={data.answers.excessLevel}
              />
            </>
          )}
          <PaymentSchedule paymentSchedule={data.paymentSchedule.schedule} />
          <br />
          {data.answers.product === 'hajpe-rf-allrisk' && (
            <a
              href={process.env.REACT_APP_DOCUMENT_VILLKOR_ALLRISK}
              target='_blank'
              rel='noreferrer'
              download
            >
              Fullständiga villkor
            </a>
          )}
          {data.answers.product === 'hajpe-rf-motor' && (
            <a
              href={process.env.REACT_APP_DOCUMENT_VILLKOR_BIL}
              target='_blank'
              rel='noreferrer'
              download
            >
              Fullständiga villkor
            </a>
          )}
          {(data.answers.product === 'hajpe-rf-contents' ||
            data.answers.product === 'hajpe-rf-houseowner') && (
            <a
              href={process.env.REACT_APP_DOCUMENT_VILLKOR_BOENDE}
              target='_blank'
              rel='noreferrer'
              download
            >
              Fullständiga villkor
            </a>
          )}
        </main>
      </PolicyLetterContainer>
    )
  );
};

export default PolicyLetterView;

const PolicyLetterContainer = styled.div`
  width: 100%;
  max-width: 56.25rem;
  margin: 1.875rem auto;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  main {
    padding: 0.625rem;

    @media (min-width: 48rem) {
      padding: 1.875rem;
    }
  }
`;
