import PolicyLetterSection from './PolicyLetterSection';

const PolicyCoverageHouseowner = ({ coverLevel, answers }) => {
  return (
    <>
      <h2>Försäkringsinnehåll</h2>
      <PolicyLetterSection>
        <table>
          <thead>
            <tr>
              <th>Försäkringsskydd</th>
              <th>Försäkringsbelopp</th>
            </tr>
          </thead>
          <tbody>
            {coverLevel === 'VillaHem' && (
              <tr>
                <td>Lösäre</td>
                <td>{Number(answers.sumInsured).toLocaleString('sv-SE')}</td>
              </tr>
            )}
            <tr>
              <td>Byggnad</td>
              <td>Fullvärde</td>
            </tr>
            {coverLevel === 'VillaHem' && (
              <tr>
                <td>Extraskydd Hem</td>
                <td>
                  {answers?.optExtendedContents &&
                  answers.optExtendedContents === 'true'
                    ? 'Ja'
                    : 'Nej'}
                </td>
              </tr>
            )}
            <tr>
              <td>Extraskydd hus</td>
              <td>
                {answers?.optExtendedVilla &&
                answers.optExtendedVilla === 'true'
                  ? 'Ja'
                  : 'Nej'}
              </td>
            </tr>
            <tr>
              <td>Extraskydd Resa</td>
              <td>
                {answers?.optTravel && answers.optTravel === 'true'
                  ? 'Ja'
                  : 'Nej'}
              </td>
            </tr>
          </tbody>
        </table>
      </PolicyLetterSection>
    </>
  );
};

export default PolicyCoverageHouseowner;
