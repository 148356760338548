import PolicyLetterSection from './PolicyLetterSection';

const PolicyCoverageContents = ({ answers }) => {
  return (
    <>
      <h2>Försäkringsinnehåll</h2>
      <PolicyLetterSection>
        <table>
          <thead>
            <tr>
              <th>Försäkringsskydd</th>
              <th>Försäkringsbelopp</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Lösäre</td>
              <td>{Number(answers.sumInsured).toLocaleString('sv-SE')}</td>
            </tr>
            <tr>
              <td>Extraskydd Hem</td>
              <td>
                {answers?.optExtra && answers.optExtra === 'true'
                  ? 'Ja'
                  : 'Nej'}
              </td>
            </tr>
            <tr>
              <td>Extraskydd Resa</td>
              <td>
                {answers?.optTravel && answers.optTravel === 'true'
                  ? 'Ja'
                  : 'Nej'}
              </td>
            </tr>
          </tbody>
        </table>
      </PolicyLetterSection>
    </>
  );
};

export default PolicyCoverageContents;
