import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/datepicker-fix.css';

import Section from '../../../components/Section';

const SalesFormAllrisk = ({
  data,
  errors,
  onChange,
  onSubmit,
  buttonText,
  isCalculatingPrice,
}) => {
  const handleDateChange = date => {
    return date;
  };

  const handleDateChangeRaw = e => e.preventDefault();

  return (
    <Section>
      <InputContainer>
        <label>Personnummer</label>
        <input
          name='socialSecurity'
          value={data.socialSecurity}
          onChange={onChange('socialSecurity')}
          autofocus
          placeholder='ÅÅÅÅMMDDnnnn'
          autoComplete='off'
        />
        <ValidationErrorText>{errors?.socialSecurity}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>Startdatum</label>
        <DatePicker
          locale='sv'
          dateFormat='yyyy-MM-dd'
          minDate={new Date()}
          selected={data.startDate || null}
          onChange={onChange('startDate', handleDateChange)}
          onChangeRaw={handleDateChangeRaw}
        />
      </InputContainer>
      <InputContainer>
        <label>Mobilnummer</label>
        <input
          type='text'
          value={data.telephoneNumber}
          onChange={onChange('telephoneNumber')}
        />
        <ValidationErrorText>{errors?.telephoneNumber}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>E-post</label>
        <input type='text' value={data.email} onChange={onChange('email')} />
        <ValidationErrorText>{errors?.email}</ValidationErrorText>
      </InputContainer>
      <button type='button' disabled={isCalculatingPrice} onClick={onSubmit}>
        {buttonText}
      </button>
    </Section>
  );
};

export default SalesFormAllrisk;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  label {
  }
`;

const ValidationErrorText = styled.span`
  font-size: 0.8125rem;
  color: #f2545b;
`;
