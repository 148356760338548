import styled from 'styled-components';

const Section = styled.section`
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 54rem) {
    border-radius: 0.5rem;
  }
`;

export default Section;
