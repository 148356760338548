import styled from 'styled-components';
import Header from '../../components/Header';

const PurchaseFailedView = () => {
  return (
    <>
      <Header />
      <ContentContainer>
        <h1>Något gick fel med din betalning</h1>

        <p>
          Det gick inte att genomföra betalningen. Vänligen kontakta oss så
          hjälper vi dig. Du når oss enklast på{' '}
          <a href='mailto:info@eirforsakring.se'>info@eirforsakring.se</a>.
        </p>
      </ContentContainer>
    </>
  );
};

export default PurchaseFailedView;

const ContentContainer = styled.div`
  padding: 0;
  max-width: 40rem;
  margin: auto;

  @media (min-width: 32rem) {
    grid-template-columns: 1fr 1fr;
  }

  h1 {
    line-height: 1.3;
    font-weight: 700;
    font-size: 1.6rem;
    text-align: center;

    @media (min-width: 35rem) {
      font-size: 3.125rem;
    }
  }
`;
