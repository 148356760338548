import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';

import { reattachService } from '@eir/services';
import { GearSpinner } from '@eir/ui';
import Header from '../../components/Header';

const AttachView = () => {
  const match = useRouteMatch('/reattach/:paymentProvider/:ledgerId');
  const { paymentProvider } = match.params;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const reattchLedger = async () => {
    try {
      const data = await reattachService(
        'Kraken',
        paymentProvider,
        match.params.ledgerId
      );
      window.location.replace(data.redirectUrl);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    reattchLedger();
  }, []);

  return (
    <AttachContainer>
      <Header>
        <h1>{error ? 'Något gick fel' : ''}</h1>
      </Header>
      {loading && (
        <main>
          <GearSpinner
            waitingMessage={`Vänligen vänta, du omdirigeras till ${paymentProvider
              .charAt(0)
              .toUpperCase()}${paymentProvider.slice(1)}..`}
          />
        </main>
      )}
      {error && (
        <main>
          Det gick inte att genomföra betalningen. Vänligen kontakta vår
          kundeservice på e-post
          <a href='mailto:info.rf@eirforsakring.se'>
            info.rf@eirforsakring.se
          </a>{' '}
          så hjälper vi dig.
        </main>
      )}
    </AttachContainer>
  );
};

export default AttachView;

const AttachContainer = styled.div`
  width: 100%;
  max-width: 56.25rem;
  margin: 1.875rem auto;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  main {
    padding: 0.625rem;

    @media (min-width: 48rem) {
      padding: 1.875rem;
    }
  }
`;

const Phone = styled.span`
  white-space: nowrap;
`;
