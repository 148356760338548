import styled from 'styled-components';

const PolicyLetterSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0.9375rem;
  background-color: #fafafa;
  border: 0.0625rem solid #cccdd3;
  border-radius: 0.625rem;

  @media (min-width: 48rem) {
    padding: 1.5625rem;
  }
`;

export default PolicyLetterSection;
