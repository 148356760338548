import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/datepicker-fix.css';

import { Icon } from '@eir/ui';

import Section from '../../../components/Section';
import SocialSecuritySeInput from './SocialSecuritySeInput';
import RadioButton from './RadioButton';

const SalesFormMotor = ({
  data,
  errors,
  onChange,
  onSubmit,
  buttonText,
  isCalculatingPrice,
}) => {
  const handleDateChange = date => {
    return date;
  };

  const handleDateChangeRaw = e => e.preventDefault();

  const handleRegistrationNumberChange = e => e.target.value.toUpperCase();

  const getCoverInfo = cover => {
    switch (cover) {
      case 'Trafik':
        return (
          <Info>
            <li>
              <Icon iconName='faCheck' color='#00854a' />
              Ersätter personskador och skador på annans egendom
            </li>
            {/* <li>
              <SmallButton type='button'>Läs mer</SmallButton>
            </li> */}
          </Info>
        );
      case 'Halv':
        return (
          <Info>
            <li>
              <Icon iconName='faCheck' color='#00854a' />
              Ersätter personskador och skador på annans egendom
            </li>
            <li>
              <Icon iconName='faCheck' color='#00854a' />
              Ersätter även andra händelser som till exempel brand, stöld,
              glasskada, motorhaveri och bärgning
            </li>
            {/* <li>
              <SmallButton type='button'>Läs mer</SmallButton>
            </li> */}
          </Info>
        );
      case 'Hel':
        return (
          <Info>
            <li>
              <Icon iconName='faCheck' color='#00854a' />
              Ersätter personskador och skador på annans egendom
            </li>
            <li>
              <Icon iconName='faCheck' color='#00854a' />
              Ersätter även andra händelser som till exempel brand, stöld,
              glasskada, motorhaveri och bärgning
            </li>
            <li>
              <Icon iconName='faCheck' color='#00854a' />
              Ersätter skador på din egen bil vid en trafikolycka, även om du
              har orsakat den
            </li>
            {/* <li>
              <SmallButton type='button'>Läs mer</SmallButton>
            </li> */}
          </Info>
        );
      default:
        return null;
    }
  };

  return (
    <Section>
      <InputContainer>
        <label>Personnummer</label>
        <SocialSecuritySeInput
          name='socialSecurity'
          value={data.socialSecurity}
          onChange={onChange('socialSecurity')}
          autofocus
        />
        <ValidationErrorText>{errors?.socialSecurity}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>Registreringsnummer</label>
        <input
          type='text'
          value={data.registrationNumber}
          onChange={onChange(
            'registrationNumber',
            handleRegistrationNumberChange
          )}
        />
        <ValidationErrorText>{errors?.registrationNumber}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>Omfattning</label>
        <RadioButtonsContainer>
          {['Trafik', 'Halv', 'Hel'].map(item => (
            <RadioButton
              key={item}
              name='coverLevel'
              value={item}
              onChange={onChange('coverLevel')}
              checked={data.coverLevel === item}
              info={getCoverInfo(item)}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      {data.coverLevel === 'Hel' && (
        <InputContainer>
          <label>Självrisk vagnskada</label>
          <RadioButtonsContainer>
            <RadioButton
              name='excessLevel'
              value='4500'
              text='4 500 kr'
              onChange={onChange('excessLevel')}
              checked={data.excessLevel === '4500'}
            />
            <RadioButton
              name='excessLevel'
              value='8000'
              text='8 000 kr'
              onChange={onChange('excessLevel')}
              checked={data.excessLevel === '8000'}
            />
            <RadioButton
              name='excessLevel'
              value='16000'
              text='16 000 kr'
              onChange={onChange('excessLevel')}
              checked={data.excessLevel === '16000'}
            />
          </RadioButtonsContainer>
        </InputContainer>
      )}
      <InputContainer>
        <label>Uppskattad körsträcka (mil/år)</label>
        <select value={data.mileage} onChange={onChange('mileage')}>
          <option value='0-1000'>0 - 1 000 mil</option>
          <option value='1001-1200'>1 001 - 1 200 mil</option>
          <option value='1201-1500'>1 201 - 1 500 mil</option>
          <option value='1501-2000'>1 501 - 2 000 mil</option>
          <option value='2001-2500'>2 001 - 2 500 mil</option>
          <option value='2501-3000'>2 501 - 3 000 mil</option>
          <option value='3001-4000'>3 001 - 4 000 mil</option>
          <option value='4000+'>Över 4 000 mil</option>
        </select>
      </InputContainer>
      <InputContainer>
        <label>Boende</label>
        <RadioButtonsContainer>
          {[
            'Villa',
            'Lägenhet - hyresrätt',
            'Lägenhet - bostadsrätt',
            'Annat',
          ].map(item => (
            <RadioButton
              key={item}
              name='dwelling'
              value={item}
              onChange={onChange('dwelling')}
              checked={data.dwelling === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Parkering</label>
        <RadioButtonsContainer>
          {[
            'Egen tomt',
            'Eget låst garage',
            'Flerbilsgarage',
            'Parkeringshus',
            'På gatan/utomhus',
          ].map(item => (
            <RadioButton
              key={item}
              name='parking'
              value={item}
              onChange={onChange('parking')}
              checked={data.parking === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Skadefria år</label>
        <select
          value={data.claimFreeYears}
          onChange={onChange('claimFreeYears')}
        >
          <option value='Missing'>Vet ej</option>
          <option value='0'>0 år</option>
          <option value='1'>1 år</option>
          <option value='2'>2 år</option>
          <option value='3'>3 år</option>
          <option value='4'>4 år</option>
          <option value='5'>5 år</option>
          <option value='6'>6 år</option>
          <option value='7'>7 år</option>
          <option value='8'>8 år</option>
          <option value='9'>9 år</option>
          <option value='10'>10 år</option>
          <option value='11'>11 år</option>
          <option value='12'>12 år</option>
          <option value='13'>13 år</option>
          <option value='14'>14 år</option>
          <option value='15'>15 år</option>
          <option value='16'>16 år</option>
          <option value='17'>17 år</option>
          <option value='18'>18 år</option>
          <option value='19+'>19 år eller fler</option>
        </select>
      </InputContainer>
      <InputContainer>
        <label>Förare under 25 år?</label>
        <RadioButtonsContainer>
          {['Ja', 'Nej'].map(item => (
            <RadioButton
              key={item}
              name='youngDriver'
              value={item}
              onChange={onChange('youngDriver')}
              checked={data.youngDriver === item}
            />
          ))}
        </RadioButtonsContainer>
        <ValidationErrorText>{errors?.youngDriver}</ValidationErrorText>
      </InputContainer>
      {data.coverLevel !== 'Trafik' && (
        <>
          <InputContainer>
            <label>Hyrbil</label>
            <RadioButtonsContainer>
              {['true', 'false'].map(item => (
                <RadioButton
                  key={item}
                  name='optHiredCar'
                  value={item}
                  onChange={onChange('optHiredCar')}
                  checked={data.optHiredCar === item}
                />
              ))}
            </RadioButtonsContainer>
          </InputContainer>
          <InputContainer>
            <label>Allrisk</label>
            <RadioButtonsContainer>
              {['true', 'false'].map(item => (
                <RadioButton
                  key={item}
                  name='optComprehensive'
                  value={item}
                  onChange={onChange('optComprehensive')}
                  checked={data.optComprehensive === item}
                />
              ))}
            </RadioButtonsContainer>
          </InputContainer>
          <InputContainer>
            <label>
              Självriskreducering för djurkollision och skadegörelse
            </label>
            <RadioButtonsContainer>
              {['true', 'false'].map(item => (
                <RadioButton
                  key={item}
                  name='optDeductible'
                  value={item}
                  onChange={onChange('optDeductible')}
                  checked={data.optDeductible === item}
                />
              ))}
            </RadioButtonsContainer>
          </InputContainer>
          <InputContainer>
            <label>Vägassistans</label>
            <RadioButtonsContainer>
              {['true', 'false'].map(item => (
                <RadioButton
                  key={item}
                  name='optAssistance'
                  value={item}
                  onChange={onChange('optAssistance')}
                  checked={data.optAssistance === item}
                />
              ))}
            </RadioButtonsContainer>
          </InputContainer>
          <InputContainer>
            <label>Trafik olycksfall</label>
            <RadioButtonsContainer>
              {['true', 'false'].map(item => (
                <RadioButton
                  key={item}
                  name='optAccident'
                  value={item}
                  onChange={onChange('optAccident')}
                  checked={data.optAccident === item}
                />
              ))}
            </RadioButtonsContainer>
          </InputContainer>
        </>
      )}
      <InputContainer>
        <label>Startdatum</label>
        <DatePicker
          locale='sv'
          dateFormat='yyyy-MM-dd'
          minDate={new Date()}
          selected={data.startDate || null}
          onChange={onChange('startDate', handleDateChange)}
          onChangeRaw={handleDateChangeRaw}
        />
      </InputContainer>
      <InputContainer>
        <label>Mobilnummer</label>
        <input
          type='text'
          value={data.telephoneNumber}
          onChange={onChange('telephoneNumber')}
        />
        <ValidationErrorText>{errors?.telephoneNumber}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>E-post</label>
        <input type='text' value={data.email} onChange={onChange('email')} />
        <ValidationErrorText>{errors?.email}</ValidationErrorText>
      </InputContainer>

      <button type='button' disabled={isCalculatingPrice} onClick={onSubmit}>
        {buttonText}
      </button>
      <Disclaimer>
        För att kunna erbjuda dig ett kundanpassat pris hämtar vi in vissa
        uppgifter om dig från tredje part, bl.a. uppgifter från Trafikverket och
        kreditupplysningsföretag. Uppgifterna behandlas i vårt system och pris
        erbjuds efter ett automatiserat beslutsfattande där dina uppgifter
        ställs mot förutbestämda parameter i våra system. Vi tillämpar ett
        automatiserat beslutsfattande för att snabbt och effektivt i realtid
        erbjuda dig en försäkring men även för att göra beslutsprocessen
        konsekvent och rättvis. Med anledning av detta är det viktigt att du
        lämnar korrekt information i fältet ovan då informationen kan få en
        påverkan på ditt pris. Eir har bedömt att det automatiserade
        beslutsfattandet är nödvändigt för att effektivt, konsekvent och
        rättvist kunna ingå ett avtal med dig.
      </Disclaimer>
    </Section>
  );
};

export default SalesFormMotor;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  label {
  }
`;

const ValidationErrorText = styled.span`
  font-size: 0.8125rem;
  color: #f2545b;
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

const Disclaimer = styled.p`
  margin-top: 1rem;
  font-size: 0.8rem;
`;

const Info = styled.ul`
  align-self: flex-start;
  list-style: none;
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-left: 0.5rem;

  li {
    display: flex;
    gap: 0.25rem;
  }
`;
