import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

html {
  font-family: SourceSansPro;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

body {
  background-color: #fff;
}

h1, h2, h3 {
  font-family: SourceSansPro;
  font-weight: 700;
}

h1 {
  font-size: 2.2rem;
  line-height: 1.3;

  @media (min-width: 40rem) {
    font-size: 3.125rem;
  }
}

h2 {
  font-size: 2rem;
}

h3 {
  font-weight: 500;
  font-size: 1.3rem;
}

a {
  color: #444;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}

input, select {
  font-family: SourceSansPro;
  font-size: 1.2rem;
  font-weight: 400;
  border: 0.0625rem solid #d3d3cf;
  background-color: #fff;
  padding: 1rem 1.5rem;
  transition: all 0.2s ease;
  border-radius: 0.5rem;

  &:focus {
    outline: 0.124rem solid #00854a;
  }
}

input {
  padding: 1rem 1.5rem;
}

select {
  padding: 0.9375rem 1.3rem;
} 

button {
  background-color: #00854a;
  font-family: SourceSansPro;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  border: none;
  padding: 1.2rem 2.4rem;
  border-radius: 3rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all .4s ease;

  &:hover {
    background-color: rgb(39, 119, 58);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

table {
  width: 100%;
  text-align: left;

  td {
    font-size: 0.75rem;
    border-top: 0.0625rem solid #e5e5e8;
    padding: 0.625rem 0;
    word-break: break-word;
    vertical-align: top;

    &:first-child {
      min-width: auto;
    }

    &:nth-child(2) {
      min-width: auto;
    }

    @media (min-width: 48rem) {
      font-size: 0.9375rem;
    }
  }

  th {
    font-size: 0.875rem;
  }
}
`;

export default GlobalStyle;
