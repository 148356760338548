import styled from 'styled-components';

import PolicyLetterSection from './PolicyLetterSection';

const PolicyOverviewContents = ({
  answers,
  start,
  end,
  name,
  policyNumber,
  policyPremium,
}) => {
  const localeStringOptions = [
    'sv-SE',
    {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
  ];

  return (
    <>
      <PolicyLetterSection>
        <ItemContainer>
          <label>Försäkringstagare</label>
          {name}
        </ItemContainer>
        <ItemContainer>
          <label>Försäkringsperiod</label>
          {start} - {end}
        </ItemContainer>
        <ItemContainer>
          <label>Försäkringsställe</label>
          {answers.address}
        </ItemContainer>
        <ItemContainer>
          <label>Försäkringsnummer</label>
          {policyNumber}
        </ItemContainer>
        <ItemContainer>
          <label>Antal hushållsmedlemmar</label>
          {answers.residents}
        </ItemContainer>
        <ItemContainer>
          <label>Självrisk hem</label>
          {Number(answers.excess).toLocaleString('sv-SE')} kr
        </ItemContainer>
        <ItemContainer>
          <label>Larm</label>
          {answers.securitySolution}
        </ItemContainer>
        <ItemContainer>
          <label>Boyta</label>
          {answers.area} m&sup2;
        </ItemContainer>
        <ItemContainer>
          <label>Hyr du ut bostaden?</label>
          {answers.rentedOut}
        </ItemContainer>
        <ItemContainer>
          <label>Premie</label>
          {(
            policyPremium.annualizedPremiumExcludingTax +
            policyPremium.annualizedTax
          ).toLocaleString(...localeStringOptions)}{' '}
          kr
        </ItemContainer>
      </PolicyLetterSection>
      <Disclaimer>
        Detta försäkringsbrev beskriver din försäkring. Det är viktigt att du
        kontrollerar att uppgifterna i försäkringsbrevet är korrekta eftersom de
        ligger till grund för din premie och hur mycket du betalar för din
        försäkring.
        <br />
        <br />
        Felaktiga uppgifter kan påverka ersättningen vid skada.
      </Disclaimer>
    </>
  );
};

export default PolicyOverviewContents;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 700;
  margin-bottom: 0.625rem;
  padding: 0 0 0.625rem;
  border-bottom: 0.0625rem solid #e5e5e8;

  &:last-child {
    border: 0;
  }

  @media (min-width: 48rem) {
    flex-direction: row;
  }

  label {
    min-width: 11.25rem;
    font-weight: 400;
  }
`;

const Disclaimer = styled.p`
  font-size: 0.8125rem;
  color: #7b7d8c;
`;
