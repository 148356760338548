import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/datepicker-fix.css';

import { Icon } from '@eir/ui';

import Section from '../../../components/Section';
import RadioButton from './RadioButton';
import RangeSlider from './RangeSlider';
import Outbuilding from './Outbuilding';

const SalesFormHouseowner = ({
  data,
  errors,
  onChange,
  onSubmit,
  buttonText,
  isCalculatingPrice,
  outbuildings,
  onAddOutbuilding,
  onChangeOutbuilding,
  onRemoveOutbuilding,
}) => {
  const handleDateChange = date => {
    return date;
  };

  const handleDateChangeRaw = e => e.preventDefault();

  return (
    <Section>
      <InputContainer>
        <label>Personnummer</label>
        <input
          name='socialSecurity'
          value={data.socialSecurity}
          onChange={onChange('socialSecurity')}
          autofocus
          placeholder='ÅÅÅÅMMDDnnnn'
          autoComplete='off'
        />
        <ValidationErrorText>{errors?.socialSecurity}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>Omfattning</label>
        <RadioButtonsContainer>
          {['Villa', 'VillaHem'].map(item => (
            <RadioButton
              key={item}
              name='coverLevel'
              value={item}
              onChange={onChange('coverLevel')}
              checked={data.coverLevel === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Boyta</label>
        <RangeSlider
          name='area'
          value={data.area}
          minValue={15}
          maxValue={200}
          onChange={onChange('area')}
          unit='kvadratmeter'
        />
      </InputContainer>
      <InputContainer>
        <label>Biyta</label>
        <RangeSlider
          name='biarea'
          value={data.biarea}
          minValue={0}
          maxValue={80}
          onChange={onChange('biarea')}
          unit='kvadratmeter'
        />
      </InputContainer>
      <InputContainer>
        <label>Antal badrum</label>
        <RadioButtonsContainer>
          {['1', '2', '3'].map(item => (
            <RadioButton
              key={item}
              name='bathrooms'
              value={item}
              text={`${item} badrum`}
              onChange={onChange('bathrooms')}
              checked={data.bathrooms === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Har huset källare?</label>
        <RadioButtonsContainer>
          {['Ja', 'Nej'].map(item => (
            <RadioButton
              key={item}
              name='basement'
              value={item}
              onChange={onChange('basement')}
              checked={data.basement === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Har huset vattenburen värme?</label>
        <RadioButtonsContainer>
          {['Ja', 'Nej'].map(item => (
            <RadioButton
              key={item}
              name='waterborneHeat'
              value={item}
              onChange={onChange('waterborneHeat')}
              checked={data.waterborneHeat === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Vilket år är huset byggt?</label>
        <RangeSlider
          name='buildingYear'
          value={data.buildingYear}
          minValue={1910}
          maxValue={new Date().getFullYear()}
          onChange={onChange('buildingYear')}
        />
      </InputContainer>
      <InputContainer>
        <label>När köpte du bostaden?</label>
        <RangeSlider
          name='yearBought'
          value={data.yearBought}
          minValue={1910}
          maxValue={new Date().getFullYear()}
          onChange={onChange('yearBought')}
        />
      </InputContainer>
      <InputContainer>
        <label>Antal personer i hushållet</label>
        <RangeSlider
          name='residents'
          value={data.residents}
          minValue={1}
          maxValue={8}
          onChange={onChange('residents')}
          unit={data.residents === '1' ? 'person' : 'personer'}
        />
      </InputContainer>
      <InputContainer>
        <label>Har du säkerhetsdörr</label>
        <RadioButtonsContainer>
          {['Ja', 'Nej'].map(item => (
            <RadioButton
              key={item}
              name='securitySolution'
              value={item}
              onChange={onChange('securitySolution')}
              checked={data.securitySolution === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Självrisk</label>
        <RadioButtonsContainer>
          {['1500', '1800', '2000', '3000', '6000'].map(item => (
            <RadioButton
              key={item}
              name='excess'
              value={item}
              text={`${Number(item).toLocaleString('sv-SE')} kr`}
              onChange={onChange('excess')}
              checked={data.excess === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Försäkringsbelopp för egendom</label>
        <RadioButtonsContainer>
          {['300000', '1000000', '1500000', '2000000'].map(item => (
            <RadioButton
              key={item}
              name='sumInsured'
              value={item}
              text={`${Number(item).toLocaleString('sv-SE')} kr`}
              onChange={onChange('sumInsured')}
              checked={data.sumInsured === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      {data.coverLevel === 'VillaHem' && (
        <InputContainer>
          <label>Extraskydd hem</label>
          <RadioButtonsContainer>
            {['true', 'false'].map(item => (
              <RadioButton
                key={item}
                name='optExtendedContents'
                value={item}
                text={item === 'true' ? 'Ja' : 'Nej'}
                onChange={onChange('optExtendedContents')}
                checked={data.optExtendedContents === item}
              />
            ))}
          </RadioButtonsContainer>
        </InputContainer>
      )}
      <InputContainer>
        <label>Extraskydd hus</label>
        <RadioButtonsContainer>
          {['true', 'false'].map(item => (
            <RadioButton
              key={item}
              name='optExtendedVilla'
              value={item}
              text={item === 'true' ? 'Ja' : 'Nej'}
              onChange={onChange('optExtendedVilla')}
              checked={data.optExtendedVilla === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Extraskydd resa</label>
        <RadioButtonsContainer>
          {['true', 'false'].map(item => (
            <RadioButton
              key={item}
              name='optTravel'
              value={item}
              text={item === 'true' ? 'Ja' : 'Nej'}
              onChange={onChange('optTravel')}
              checked={data.optTravel === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      <InputContainer>
        <label>Hyr du ut bostaden?</label>
        <RadioButtonsContainer>
          {['Ja', 'Nej'].map(item => (
            <RadioButton
              key={item}
              name='rentedOut'
              value={item}
              onChange={onChange('rentedOut')}
              checked={data.rentedOut === item}
            />
          ))}
        </RadioButtonsContainer>
      </InputContainer>
      {data.coverLevel === 'VillaHem' &&
        outbuildings.map((outbuilding, index) => (
          <Outbuilding
            key={index}
            outbuilding={outbuilding}
            onChange={onChangeOutbuilding}
            onRemove={onRemoveOutbuilding}
            index={index}
          />
        ))}
      {outbuildings.length < 5 && (
        <AddBuildingButton onClick={onAddOutbuilding}>
          <Icon iconName='faPlus' />
          Lägg till komplementsbyggnad
        </AddBuildingButton>
      )}
      <InputContainer>
        <label>Startdatum</label>
        <DatePicker
          locale='sv'
          dateFormat='yyyy-MM-dd'
          minDate={new Date()}
          selected={data.startDate || null}
          onChange={onChange('startDate', handleDateChange)}
          onChangeRaw={handleDateChangeRaw}
        />
      </InputContainer>
      <InputContainer>
        <label>Mobilnummer</label>
        <input
          type='text'
          value={data.telephoneNumber}
          onChange={onChange('telephoneNumber')}
        />
        <ValidationErrorText>{errors?.telephoneNumber}</ValidationErrorText>
      </InputContainer>
      <InputContainer>
        <label>E-post</label>
        <input type='text' value={data.email} onChange={onChange('email')} />
        <ValidationErrorText>{errors?.email}</ValidationErrorText>
      </InputContainer>
      <button type='button' disabled={isCalculatingPrice} onClick={onSubmit}>
        {buttonText}
      </button>
    </Section>
  );
};

export default SalesFormHouseowner;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  label {
  }
`;

const ValidationErrorText = styled.span`
  font-size: 0.8125rem;
  color: #f2545b;
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

const AddBuildingButton = styled.button`
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  background-color: transparent;
  color: #3db7e4;
  border: 0.0625rem solid #3db7e4;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
`;
