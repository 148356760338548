import styled from 'styled-components';

const Header = ({ children }) => {
  return (
    <ViewHeader>
      <LogoContainer>
        <Logo
          src='https://d2l0gvltru79ov.cloudfront.net/partners/hajpe-rf/Reumatikerforbundet.png'
          alt='Reumatikerförbundet'
        />
        <Logo
          src='https://d2l0gvltru79ov.cloudfront.net/partners/eir/eir-logo-2.png'
          alt='Eir Försäkring AB'
        />
      </LogoContainer>
      {children}
    </ViewHeader>
  );
};

export default Header;

const ViewHeader = styled.header`
  margin: auto;
  margin-bottom: 1.5rem;
  background-color: #fff;
  padding: 2rem 1rem;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 2rem;
`;

const Logo = styled.img`
  width: auto;
  height: 4.5rem;
`;
