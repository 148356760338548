import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';

import { configureAmplify } from '@eir/aws-authorization';

import OnlineSalesView from './views/onlineSales/OnlineSalesView';
import PaymentView from './views/payment/PaymentView';
import GlobalStyle from './styles/globalStyle';
import AttachView from './views/attach/AttachView';
import PolicyLetterView from './views/policyLetter/PolicyLetterView';
import PurchaseSuccessView from './views/purchaseSuccessView/PurchaseSuccessView';
import PurchaseFailedView from './views/purchaseFailedView/PurchaseFailedView';

registerLocale('sv', sv);

configureAmplify(
  {
    endpointName: 'Kraken',
    endpointUrl: process.env.REACT_APP_AWS_ENDPOINT_URL,
  },
  true
);

const App = () => {
  const onResize = () => {
    window.parent.postMessage(
      {
        type: 'resize',
        height: document.body.scrollHeight,
      },
      '*'
    );
  };

  useEffect(() => {
    onResize();
    new ResizeObserver(_ => onResize()).observe(document.body);
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel='stylesheet'
          type='text/css'
          href='https://d2l0gvltru79ov.cloudfront.net/css/eir-partners-fonts-source-sans-pro.css'
        />
      </Helmet>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route exact path='/teckna/:product' component={OnlineSalesView} />
          <Route
            exact
            path='/pay/:paymentProvider/:quoteId'
            component={PaymentView}
          />
          <Route
            exact
            path='/reattach/:paymentProvider/:ledgerId'
            component={AttachView}
          />
          <Route exact path='/p/:policyId' component={PolicyLetterView} />
          <Route exact path='/tecknad' component={PurchaseSuccessView} />
          <Route exact path='/ooops' component={PurchaseFailedView} />
        </Switch>
      </Router>
      <Footer>
        <a
          href='https://www.eirforsakring.com/sv/integritetspolicy'
          target='_blank'
          rel='noreferrer'
        >
          Integritetspolicy
        </a>
        <AddressBlock>
          <span>Försäkringsgivare:</span>
          <span>Eir Försäkring AB, org. 559166-0617</span>
          <span>Norrlandsgatan 12, 111 43 Stockholm</span>
        </AddressBlock>
      </Footer>
    </>
  );
};

export default App;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  color: #252555;
  margin: 0 2rem;
  margin-top: 1rem;
  padding: 2rem 0;
  font-size: 0.6875rem;
  border-top: 0.0625rem solid #252555;
`;

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;
